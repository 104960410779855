<template>
    <div class="full">
        <h1 class="text-center pa-4">Chat Component setup on Custom CMS</h1>
        <p>1. Add styling file to the END of <code>&lt;head&gt;</code></p>
        <code>
            &lt;link href="https://storage.googleapis.com/evenstad-chat/{{assistantId}}/evenstad-chat.css" rel="stylesheet"&gt;
        </code>
        <p class="pt-3">2. Add following script to the END of <code>&lt;body&gt;</code></p>
        <code>
            &lt;script src="https://storage.googleapis.com/evenstad-chat/{{assistantId}}/chat.js">&lt;/script&gt;
        </code>
    </div>
</template>

<script>
export default {
    name:'CustomSetup',
    props: {
        assistantId: {
            type: String,
            // Object or array defaults must be returned from
            // a factory function
            default: function () {
                return '[REPLACE_ASSISTANT_ID]'
            }
        }
    },
    created(){
        console.log(this.$route.query)
    }
}
</script>

<style>
</style>