<template>
    <div class="full">
        <h1 class="text-center pa-4">Chat Component setup on Wordpress CMS</h1>
        <p>1. Download the Wordpress plugin (.zip file)</p>
        <a href="https://storage.googleapis.com/evenstad-chat/plugins/wordpress/evenstad-chat.zip" >https://storage.googleapis.com/evenstad-chat/plugins/wordpress/evenstad-chat.zip</a>
        <p class="mt-4">2. Log in to your Wordpress Admin Panel</p>
        <p>3. Go to "Plugins" --> "Add New" and click on "Upload Plugin" button on the top of the site</p>
        <p>4. Click on "Choose File" and select downloaded .zip file</p>
        <p>5. Click on "Install Now" button</p>
        <p>6. Go to "Settings" --> "Evenstad Chat"</p>
        <p v-if="assistantId !== '[REPLACE_ASSISTANT_ID]'">7. Write "{{assistantId}}" (without quotes) to Assistant ID field and click on "Save Changes".</p>
        <p v-else>7. Write your assistant's id to the Assistant ID field (if you don't know this, please contact support!) and click on "Save Changes".</p>
        <p>8. Congratulations, everything's done!</p>
    </div>
</template>

<script>
export default {
    name:'CMSSetupWordpress',
    props: {
        assistantId: {
            type: String,
            // Object or array defaults must be returned from
            // a factory function
            default: function () {
                return '[REPLACE_ASSISTANT_ID]'
            }
        }
    },
    created(){
        console.log(this.$route.query)
    }
}
</script>

<style>
</style>